<template>
  <v-dialog
    v-model="isOpen"
    width="90vw"
    persistent
    @click:outside="tryClose"
  >
    <v-card>
      <v-card-title>
        Check verification by access to channel
        <v-spacer />
        <v-btn
          icon
          @click="tryClose"
        >
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col>
              <label for="verified_channel_id">
                Channel Id
              </label>

              <v-text-field
                id="verified_channel_id"
                v-model="formData.verified_channel_id"
                hint="The channel id that is available to the verified user"
                :required="[isTrue]"
                :rules="[simpleRequired]"
              />
            </v-col>
          </v-row>
          <ActionsDelay
            v-model="formData"
          ></ActionsDelay>
        </v-form>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :loading="loading"
          @click="submit"
        >
          Check
        </v-btn>
        <v-btn
          :disabled="loading"
          @click="tryClose"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>

    <!--
      CONFIRM CANCEL UPDATE
    -->
    <ConfirmDialog
      ref="updateConfirm"
      :width="400"
      :z-index="99"
    >
      <template v-slot:default="{agree, cancel}">
        <v-card>
          <v-card-title>Confirm update cancel</v-card-title>
          <v-card-text>
            You have unsaved changes, you wanna leave
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              @click="agree"
            >
              Agree
            </v-btn>
            <v-btn
              color="primary"
              @click="cancel"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </ConfirmDialog>
  </v-dialog>
</template>

<script>
import { mdiClose } from '@mdi/js'
import _cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'

import DiscordApi from '@core/api/discord'
import {
  isTrue, simpleRequired, isMore, integerValidator, maximumValue, maxMultiplyValue,
} from '@core/utils/validation'
import ActionsDelay from '@/components/ActionsDelay.vue'
import ConfirmDialog from '../../../ConfirmDialog.vue'

const DEFAULT_DATA = {
  verified_channel_id: null,
  account_delay: 15,
  batch_delay: 1,
  batch_size: 5,
}

export default {
  components: { ConfirmDialog, ActionsDelay },
  props: {
    projectId: {
      type: Number,
      default: null,
    },
    roles: {
      type: Array, default: () => [],
    },
    selectedOptions: {
      type: Object,
      default: () => ({}),
    },
    channel: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      icons: {
        mdiClose,
      },
      loading: false,
      isOpen: false,
      test: this.channel,
      initFormData: DEFAULT_DATA,
      formData: DEFAULT_DATA,
    }
  },
  computed: {
    isChanged() {
      return !isEqual(this.initFormData, this.formData)
    },
  },

  watch: {
    channel() {
      this.initFormData = { ...DEFAULT_DATA, verified_channel_id: this.channel }
      this.formData = { ...DEFAULT_DATA, verified_channel_id: this.channel }
    },
  },
  methods: {
    isTrue,
    simpleRequired,
    isMore,
    integerValidator,
    maximumValue,
    maxMultiplyValue,
    open() {
      this.isOpen = true
      this.formData = _cloneDeep(this.initFormData)
    },
    async submit() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true
          await DiscordApi.Project.checkVerification(this.projectId, {
            ...this.selectedOptions,
            ...this.formData,
            account_delay: +this.formData.account_delay,
            batch_delay: +this.formData.batch_delay,
            batch_size: +this.formData.batch_size,
          })
          this.reset()
        } catch (error) {
          this.$message.error(error)
        } finally {
          this.loading = false
        }
      }
    },
    reset() {
      this.isOpen = false
      this.formData = _cloneDeep(this.initFormData)
    },
    async tryClose() {
      if (this.isChanged && await this.$refs.updateConfirm.open()) {
        this.reset()
      } else this.reset()
    },
  },
}
</script>

<style>

</style>

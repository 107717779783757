<template>
  <v-form ref="form">
    <v-row>
      <v-col
        cols="12"
        md="4"
      >
        <h3>General</h3>
        <v-col>
          <label for="name">Name</label>
        </v-col>
        <v-col>
          <v-text-field
            id="name"
            v-model="formData.name"
            outlined
            dense
            hide-details="auto"
            placeholder="Enter name"
            :disabled="loading"
            :rules="[required]"
          ></v-text-field>
        </v-col>
        <v-col>
          <label for="invitationCode">Invitation code</label>
        </v-col>
        <v-col>
          <v-text-field
            id="invitationCode"
            v-model="formData.invitation_code"
            outlined
            dense
            hide-details="auto"
            placeholder="Enter invitation code"
            :disabled="loading"
            :rules="[required]"
          ></v-text-field>
        </v-col>
        <v-col>
          <label for="guild_id">Server Id</label>
        </v-col>
        <v-col>
          <v-text-field
            id="guild_id"
            v-model="formData.guild_id"
            outlined
            dense
            type="number"
            hide-details="auto"
            placeholder="Enter server id"
            :disabled="loading"
            :rules="[required]"
          ></v-text-field>
        </v-col>
        <v-col>
          <label>Mint date</label>
          <DatePicker
            v-model="formData.mint_date"
          />
        </v-col>
        <v-col>
          <v-switch
            id="skip_captcha"
            v-model="formData.skip_captcha"
            label="Skip captcha"
            outlined
            dense
            hide-details="auto"
            :disabled="loading||disableSkipCaptcha"
            @change="resetVerification"
          ></v-switch>
        </v-col>
        <v-alert
          v-if="formData.skip_captcha || isFilter"
          border="bottom"
          colored-border
          type="warning"
          elevation="2"
        >
          {{ skipCaptchaText }}
        </v-alert>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <h3>Verification</h3>

        <v-col>
          <label for="verification_type">Verification type</label>
        </v-col>
        <v-col>
          <v-select
            id="verification_type"
            v-model="formData.verification_type"
            :items="verificationsTypesIsCaptcha"
            outlined
            dense
            hide-details="auto"
            :disabled="loading"
          />
        </v-col>
        <template v-if="formData.verification_type !== 'skip' && formData.verification_type !== 'captcha_slash_cmd_bot'">
          <v-col>
            <label for="channelId">Message Link</label>
          </v-col>
          <v-col>
            <v-text-field
              id="messageId"
              v-model="formData.message_url"
              outlined
              dense
              hide-details="auto"
              placeholder="Enter message link"
              :disabled="loading"
              :rules="[required, urlValidator]"
            ></v-text-field>
          </v-col>
        </template>
        <template v-if="formData.verification_type === 'pandez_guard_code'">
          <v-col>
            <label for="pandez_code">Pandez Code</label>
          </v-col>
          <v-col>
            <v-text-field
              id="pandez_code"
              v-model="formData.pandez_code"
              outlined
              dense
              hide-details="auto"
              placeholder="Enter pandez code"
              :disabled="loading"
              :rules="[required]"
            ></v-text-field>
          </v-col>
        </template>
        <template v-if="formData.verification_type == 'emoji_add_reaction'">
          <v-col>
            <label for="channelId">Emoji position number</label>
          </v-col>
          <v-col>
            <v-text-field
              id="emoji"
              v-model.number="formData.position_number"
              type="number"
              outlined
              dense
              hide-details="auto"
              placeholder="Enter emoji position number"
              :disabled="loading"
              :rules="[simpleRequired]"
            ></v-text-field>
          </v-col>
        </template>
        <template v-if="formData.verification_type === 'captcha_slash_cmd_bot'">
          <v-col>
            <label for="channel_id">Channel id</label>
          </v-col>
          <v-col>
            <v-text-field
              id="channel_id"
              v-model="formData.channel_id"
              outlined
              dense
              type="number"
              hide-details="auto"
              placeholder="Enter channel id"
              :disabled="loading"
              :rules="[required]"
            ></v-text-field>
          </v-col>
        </template>
        <template v-if="formData.verification_type == 'click_button'">
          <v-col>
            <label for="verifyButton">Button position number</label>
          </v-col>
          <v-col>
            <v-text-field
              id="verifyButton"
              v-model.number="formData.position_number"
              type="number"
              outlined
              dense
              hide-details="auto"
              placeholder="Enter button position number"
              :disabled="loading"
              :rules="[simpleRequired]"
            ></v-text-field>
          </v-col>
        </template>
        <template v-if="formData.verification_type !== 'skip'">
          <h3 class="mt-4">
            Verified channel
          </h3>
          <v-col>
            <label for="verified_channel_id">The channel id that is available to the verified user (For example General, Announcement)</label>
          </v-col>
          <v-col>
            <v-text-field
              id="verified_channel_id"
              v-model="formData.verified_channel_id"
              outlined
              dense
              hide-details="auto"
              placeholder="Enter channel id"
              :disabled="loading"
              :rules="[required]"
            ></v-text-field>
          </v-col>
        </template>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <h3>Giveaway</h3>

        <v-col>
          <label for="giveawayChannelId">Giveaway channel Id</label>
        </v-col>
        <v-col>
          <v-text-field
            id="giveawayChannelId"
            v-model="formData.giveaway_channel_id"
            outlined
            dense
            type="number"
            hide-details="auto"
            :disabled="loading"
          />
        </v-col>
        <v-col>
          <label for="giveaway_sync_interval">Giveaway sync interval (min.)</label>
        </v-col>
        <v-col>
          <v-text-field
            id="giveaway_sync_interval"
            v-model.number="formData.giveaway_sync_interval"
            type="number"
            outlined
            dense
            hide-details="auto"
            :min="1"
            :disabled="loading"
            :rules="[simpleRequired, isMore(0),integerValidator]"
          />
        </v-col>
        <v-col class="d-flex align-center">
          <v-radio-group
            id="useEmoji"
            v-model="formData.giveaway_top_3"
            label="Use emoji:"
          >
            <v-radio
              label="First and top 3"
              :value="false"
              hide-details="auto"
              :disabled="loading"
            ></v-radio>
            <v-radio
              label="Only first"
              :value="true"
              hide-details="auto"
              :disabled="loading"
            ></v-radio>
          </v-radio-group>
        </v-col>
        <v-col class="d-flex align-center">
          <v-radio-group
            id="useBotOnly"
            v-model="formData.giveaway_bot_only"
            label="Use bot only:"
          >
            <v-radio
              label="Bot and user message"
              :value="false"
              hide-details="auto"
              :disabled="loading"
            ></v-radio>
            <v-radio
              label="Only bot message"
              :value="true"
              hide-details="auto"
              :disabled="loading"
            ></v-radio>
          </v-radio-group>
        </v-col>
        <v-col class="d-flex align-center">
          <v-radio-group
            id="participateGiveaway"
            v-model="formData.giveaway_button"
            label="Participate in giveaway by button:"
          >
            <v-radio
              label="Giveaway only emoji"
              :value="false"
              hide-details="auto"
              :disabled="loading"
            ></v-radio>
            <v-radio
              label="Giveaway by button and emoji"
              :value="true"
              hide-details="auto"
              :disabled="loading"
            ></v-radio>
          </v-radio-group>
        </v-col>
        <v-col>
          <label for="giveaway_last_message_url">Last checked message</label>
        </v-col>
        <v-col>
          <v-text-field
            id="giveaway_last_message_url"
            v-model.number="formData.giveaway_last_message_url"
            outlined
            dense
            hide-details="auto"
            :disabled="loading"
            :rules="[urlValidator]"
          />
        </v-col>
        <v-col>
          <label for="batch_delay">
            Batch Delay
            <span>(min.)</span>
          </label>
        </v-col>
        <v-col>
          <v-text-field
            id="batch_delay"
            v-model.number="formData.giveaway_batch_delay"
            outlined
            dense
            type="number"
            :rules="[simpleRequired,isMore(1),integerValidator]"
          />
        </v-col>
        <v-col>
          <label for="account_delay">
            Account Delay
            <span>(sec.)</span>
          </label>
        </v-col>
        <v-col>
          <v-text-field
            id="account_delay"
            v-model.number="formData.giveaway_account_delay"
            outlined
            dense
            type="number"
            :rules="[simpleRequired,isMore(1),integerValidator,maxMultiplyValue(formData.giveaway_batch_size,900)]"
          />
        </v-col>
        <v-col>
          <label for="batch_size">
            Batch size
          </label>
        </v-col>
        <v-col>
          <v-text-field
            id="batch_size"
            v-model.number="formData.giveaway_batch_size"
            outlined
            dense
            type="number"
            :rules="[simpleRequired,isMore(1),integerValidator,maximumValue(30),maxMultiplyValue(formData.giveaway_account_delay,900)]"
          />
        </v-col>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { ref } from '@vue/composition-api'
import _cloneDeep from 'lodash/cloneDeep'
import { verificationsTypes } from '@core/constants'
import {
  required, simpleRequired, urlValidator, integerValidator,
  isMore, maxMultiplyValue, maximumValue,
} from '@core/utils/validation'
import { mapState } from 'vuex'
import DatePicker from '../../DatePicker.vue'
import store from '@/store'
import userStoreModule, { USER_MODULE_NAME } from '@/store/modules/userStoreModule'

export default {
  components: { DatePicker },
  props: {
    loading: Boolean,
    project: { type: Object, default: () => ({}) },
  },
  computed: {
    verificationsTypesIsCaptcha() {
      const verificationsTypesIsCaptcha = _cloneDeep(this.verificationsTypes)
      if (this.isFilter) {
        return verificationsTypesIsCaptcha
          .filter(({ value }) => !['captcha_bot',
            'wick',
            'pandez_guard',
            'captcha_slash_cmd_bot',
            'pandez_guard_code',
            'server_supervisor',
            'captcha_image_bot',
            'vulcan',
            'supervisor_calculator'].includes(value))
      }

      return verificationsTypesIsCaptcha
    },
    ...mapState({
      user(state) {
        return state[USER_MODULE_NAME]?.user
      },
    }),
  },
  mounted() {
    if (!store.hasModule(USER_MODULE_NAME)) {
      store.registerModule(USER_MODULE_NAME, userStoreModule)
    }

    if (this.project.guild_id && this.project.giveaway_channel_id && this.project.giveaway_last_message) {
      this.formData = {
        ...this.formData,
        giveaway_last_message_url: `https://discord.com/channels/${this.project.guild_id}/${this.project.giveaway_channel_id}/${this.project.giveaway_last_message}`,
      }
    }
    this.checkSkipCaptcha()
  },
  methods: {
    resetVerification(skipCaptcha) {
      if (skipCaptcha) {
        this.isFilter = true
        this.formData = {
          ...this.formData,
          verification_type: 'skip',
          verified_channel_id: null,
          giveaway_sync_interval: 60,
          message_url: '',
          pandez_code: '',
          position_number: 1,
          channel_id: '',
          mint_date: null,
          giveaway_last_message_url: '',
          giveaway_batch_size: '',
          giveaway_batch_delay: '',
          giveaway_account_delay: '',
        }
      } else {
        this.isFilter = false
      }
    },
    setSkipCaptcha(text) {
      this.formData.skip_captcha = true
      this.disableSkipCaptcha = true
      this.skipCaptchaText = text
    },
    checkSkipCaptcha() {
      const imageSolverName = this.user.image_solver.split('_').join('')

      if (this.user && !this.user.capsolver_api_key && !this.user[`${imageSolverName}_api_key`]) {
        this.isFilter = true
        this.setSkipCaptcha('To solve captcha when entering the server, set api key for hCaptcha solver  in settings. For additional verification to be available, set the API key for the Image captcha solution in settings')
      } else if (this.user && !this.user.capsolver_api_key && this.user[`${imageSolverName}_api_key`]) {
        this.setSkipCaptcha('To solve captcha when entering the server, set api key for hCaptcha solver  in setting')
      } else if (this.user && this.user.capsolver_api_key && !this.user[`${imageSolverName}_api_key`]) {
        this.isFilter = true
        this.skipCaptchaText = 'For additional verification to be available, set the API key for the Image captcha solution in settings'
      }
    },
  },
  setup({ project }) {
    const form = ref(null)
    const validate = () => form.value.validate()
    const reset = () => {
      form.value.reset()
    }
    const formData = ref(project)
    const disableSkipCaptcha = ref(false)
    const skipCaptchaText = ref('We will skip captcha upon connection and some actions will be unavailable')
    const isFilter = ref(false)

    const submit = async () => formData.value

    return {
      form,
      formData,
      verificationsTypes,
      validate,
      reset,
      required,
      urlValidator,
      submit,
      simpleRequired,
      isMore,
      integerValidator,
      maxMultiplyValue,
      maximumValue,
      disableSkipCaptcha,
      skipCaptchaText,
      isFilter,
    }
  },
}
</script>

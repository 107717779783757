<template>
  <v-dialog
    v-model="isOpen"
    width="90vw"
    persistent
    @click:outside="tryClose"
  >
    <v-card>
      <v-card-title>
        Click Button
        <v-spacer />
        <v-btn
          icon
          @click="tryClose"
        >
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-stepper v-model="step">
          <v-stepper-header>
            <v-stepper-step
              :complete="step > 1"
              step="1"
            >
              Set message
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              :complete="step > 2"
              step="2"
            >
              Select button
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="3">
              Set delay
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card>
                <v-form ref="form1">
                  <v-row>
                    <v-col>
                      <label for="message_link">
                        Message Link
                      </label>

                      <v-text-field
                        id="message_link"
                        v-model="formData.message_url"
                        :rules="[isNotEmptyString, urlValidator]"
                      />
                    </v-col>
                  </v-row>
                </v-form>
              </v-card>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-card>
                <v-form ref="form2">
                  <v-row>
                    <v-col>
                      <label for="button">
                        Button
                      </label>

                      <v-select
                        id="button"
                        v-model="formData.custom_id"
                        :items="buttons"
                        :rules="[()=>!!formData.custom_id]"
                      />
                    </v-col>
                  </v-row>
                </v-form>
              </v-card>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-card>
                <v-form ref="form3">
                  <ActionsDelay
                    v-model="formData"
                  ></ActionsDelay>
                </v-form>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :loading="loading"
          @click="submit"
        >
          Next
        </v-btn>
        <v-btn
          :disabled="loading"
          @click="tryClose"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>

    <!--
      CONFIRM CANCEL UPDATE
    -->
    <ConfirmDialog
      ref="updateConfirm"
      :width="400"
      :z-index="99"
    >
      <template v-slot:default="{agree, cancel}">
        <v-card>
          <v-card-title>Confirm update cancel</v-card-title>
          <v-card-text>
            You have unsaved changes, you wanna leave
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              @click="agree"
            >
              Agree
            </v-btn>
            <v-btn
              color="primary"
              @click="cancel"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </ConfirmDialog>
  </v-dialog>
</template>

<script>
import { mdiClose } from '@mdi/js'
import _cloneDeep from 'lodash/cloneDeep'
import _pick from 'lodash/pick'
import isEqual from 'lodash/isEqual'
import {
  urlValidator, isNotEmptyString, maximumValue, maxMultiplyValue, simpleRequired, isMore, integerValidator,
} from '@core/utils/validation'
import DiscordApi from '@core/api/discord'
import ActionsDelay from '@/components/ActionsDelay.vue'

import ConfirmDialog from '../../../ConfirmDialog.vue'

const DEFAULT_DATA = {
  custom_id: null,
  message_url: '',
  account_delay: 15,
  batch_delay: 1,
  batch_size: 5,
}

export default {
  components: { ConfirmDialog, ActionsDelay },
  props: {
    projectId: {
      type: Number,
      default: null,
    },
    selectedOptions: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    step: 1,
    icons: {
      mdiClose,
    },
    loading: false,
    buttons: [],
    isOpen: false,
    accounts: [],
    initFormData: DEFAULT_DATA,
    formData: DEFAULT_DATA,
  }),
  computed: {
    isChanged() {
      return !isEqual(this.initFormData, this.formData)
    },
  },
  methods: {
    urlValidator,
    isNotEmptyString,
    maximumValue,
    maxMultiplyValue,
    simpleRequired,
    isMore,
    integerValidator,
    open(accounts) {
      this.isOpen = true
      this.accounts = accounts.map(el => el.id)
      this.formData = _cloneDeep(this.initFormData)
    },
    async submit() {
      try {
        this.loading = true
        switch (this.step) {
          case (1):
            await this.getButtons()
            break
          case (2):
            break
          case (3):
            await this.clickButton()
            break
          default:
            break
        }
        if (this.$refs.form1.validate() || this.$refs.form2.validate()) {
          this.step += 1
        }
      } catch (err) {
        this.$message.error(err)
      }
      this.loading = false
    },

    async getButtons() {
      if (this.$refs.form1.validate()) {
        const { data } = await DiscordApi.Project.buttonByMessage({
          ...this.selectedOptions,
          ..._pick(this.formData, ['message_url']),
        })

        this.buttons = data.button_list.map(({ label, value }) => ({ text: label, value }))
      }
    },

    async clickButton() {
      if (this.$refs.form3.validate()) {
        try {
          this.loading = true
          await DiscordApi.Project.clickButton(this.projectId, {
            ...this.selectedOptions,
            ...this.formData,
          })
          this.reset()
        } catch (error) {
          this.$message.error(error)
          throw error
        } finally {
          this.loading = false
        }
      }
    },

    reset() {
      this.step = 1
      this.isOpen = false
      this.formData = _cloneDeep(this.initFormData)
    },
    async tryClose() {
      if (this.isChanged && await this.$refs.updateConfirm.open()) {
        this.reset()
      } else this.reset()
    },
  },
}
</script>

<style>

</style>

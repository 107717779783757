<template>
  <div>
    <v-skeleton-loader
      :loading="initialLoading"
      type="heading, card, table-tbody"
    >
      <v-layout column>
        <h2 class="flex-wrap d-flex align-center">
          <v-btn
            :loading="rolesRefreshingLoading"
            class="mr-4 mb-5"
            @click="tryRefreshRoles"
          >
            <v-icon left>
              {{ icons.mdiRefresh }}
            </v-icon>Refresh Roles
          </v-btn>
          <v-btn
            class="mr-4 mb-5"
            @click="() => {
              if(selectedAccounts.length>0){
                onActionSelected('checkVerification')
              }else{
                $message.info('No accounts selected')
              }}"
          >
            <v-icon left>
              {{ icons.mdiMarkerCheck }}
            </v-icon>
            Check Verification
          </v-btn>
          <v-btn
            class="mr-4 mb-5"
            @click="() => {
              if(selectedAccounts.length>0){
                onActionSelected('verifyAccount')
              }else{
                $message.info('No accounts selected')
              }}"
          >
            <v-icon left>
              {{ icons.mdiAccountCheck }}
            </v-icon>
            Verify Account
          </v-btn>
          <v-btn
            class="mr-4 mb-5"
            @click="() =>{
              if(selectedAccounts.length>0){
                onActionSelected('joinServer')}
              else{
                $message.info('No accounts selected')
              }
            }"
          >
            <v-icon left>
              {{ icons.mdiServerPlus }}
            </v-icon>
            Join server
          </v-btn>
          <v-btn
            class="mb-5 mr-4"
            @click="() =>{
              if(selectedAccounts.length>0){
                onActionSelected('exportAccounts')}
              else{
                $message.info('No accounts selected')
              }
            }"
          >
            <v-icon left>
              {{ icons.mdiDownload }}
            </v-icon>
            Export accounts
          </v-btn>
          <v-btn
            class="mb-5 mr-4"
            @click="() =>{
              if(selectedAccounts.length>0){
                onActionSelected('updateInfo')}
              else{
                $message.info('No accounts selected')
              }
            }"
          >
            <v-icon left>
              {{ icons.mdiUpdate }}
            </v-icon>
            Update Info
          </v-btn>
          <v-btn
            class="mb-5"
            @click="checkParseAccount"
          >
            <v-icon left>
              {{ icons.mdiExport }}
            </v-icon>
            Parse
          </v-btn>
          <v-spacer />
          <v-btn
            icon
            :loading="loading"
            class="mb-5"
            @click="() => $refs.editProjectModal.open(details)"
          >
            <v-icon>{{ icons.mdiPencil }}</v-icon>
          </v-btn>
          <ProjectEditModal
            ref="editProjectModal"
            @update="tryUpdateProject"
          />
        </h2>
        <v-alert
          v-if="isRefreshRolesRequired"
          dense
          border="left"
          type="warning"
        >
          You need to refresh project roles
        </v-alert>
        <v-row>
          <v-col
            cols="12"
          >
            <v-card>
              <v-expansion-panels
                flat
                :value="1"
              >
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <v-card-title>
                      Giveaway
                    </v-card-title>
                    <v-spacer />
                    <v-layout
                      justify-end
                      align-center
                    >
                      <v-card-subtitle
                        v-if="!details.giveaway_channel_id"
                        class="py-0 yellow--text"
                      >
                        Channel is required
                      </v-card-subtitle>
                      <v-switch
                        v-model="details.giveaway_monitor"
                        class="mt-0 mr-4"
                        :loading="loading"
                        :disabled="!details.giveaway_channel_id"
                        hide-details
                        @click.stop
                        @change="(value) => tryUpdateProject({
                          ...details,
                          giveaway_monitor: value
                        })"
                      ></v-switch>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            :loading="giveawayLoading"
                            icon
                            class="mr-4"
                            v-bind="attrs"
                            @click.stop
                            @click="checkGiveaway"
                            v-on="on"
                          >
                            <v-icon>
                              {{ icons.mdiSync }}
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Check giveaway</span>
                      </v-tooltip>
                    </v-layout>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-card-text>
                      <v-row>
                        <v-col cols="3">
                          Channel:
                        </v-col>
                        <v-col cols="3">
                          {{ details.giveaway_channel_id|placeholder }}
                        </v-col>
                        <v-col cols="3">
                          Use top 3 emoji:
                        </v-col>
                        <v-col cols="3">
                          <TrueFalse :value="details.giveaway_top_3" />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="3">
                          Last sync:
                        </v-col>
                        <v-col cols="3">
                          {{ details.giveaway_last_sync |placeholder }}
                        </v-col>
                        <v-col cols="3">
                          Only bot:
                        </v-col>
                        <v-col cols="3">
                          <TrueFalse :value="details.giveaway_bot_only" />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="3">
                          Sync:
                        </v-col>
                        <v-col cols="3">
                          every {{ details.giveaway_sync_interval }} minutes
                        </v-col>
                        <v-col cols="3">
                          Giveaway by button:
                        </v-col>
                        <v-col cols="3">
                          <TrueFalse :value="details.giveaway_button" />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          v-if="isMessageLinkAvailable"
                          cols="3"
                        >
                          Last checked message:
                        </v-col>
                        <v-col
                          v-if="isMessageLinkAvailable"
                          cols="3"
                        >
                          <a :href="messageLink">
                            {{ messageLink }}
                          </a>
                        </v-col>
                        <v-col
                          v-if="details.mint_date"
                          cols="3"
                        >
                          Mint date:
                        </v-col>
                        <v-col
                          v-if="details.mint_date"
                          cols="3"
                        >
                          {{ details.mint_date }}
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card>
          </v-col>
        </v-row>
        <v-card class="mt-4">
          <v-card-title>
            Accounts
            <MultiActions
              :is-disabled="selectedAccounts.length===0"
              @select="onActionSelected"
            />
            <v-spacer />
            <TableSearch
              v-model="tableOptions.search"

              :show-filters.sync="showFilters"
            />
            <v-spacer />
            <AddNewAccount
              :is-project-details="true"
              :project-id="projectId"
              @submitted="fetchProjectAccounts"
            />
          </v-card-title>
          <v-card-text>
            <v-data-table
              v-model="selectedAccounts"
              hide-default-footer
              :mobile-breakpoint="0"
              :server-items-length="total"
              item-key="id"
              loading-text="Loading accounts ..."
              show-expand
              show-select
              :loading="loading"
              :items="accounts"
              :headers="accountHeaders"
              :options.sync="tableOptions"
              :footer-props="{
                'items-per-page-options': pages,
                'show-first-last-page': true,
                'show-current-page': true,
              }"
            >
              <template
                v-slot:top
              >
                <v-layout
                  v-show="showFilters"
                  class="mx-2 mb-4"
                >
                  <v-row>
                    <v-col cols="3">
                      <v-select
                        v-model="tableOptions.status"
                        label="Status"
                        placeholder="Select status"
                        multiple
                        hide-details
                        :items="statuses"
                        clearable
                      ></v-select>
                      <SelectTag
                        v-model="tableOptions.tags"
                        multiple
                      />
                    </v-col>
                    <v-col cols="3">
                      <v-select
                        v-model="tableOptions.type"
                        multiple
                        label="Types"
                        placeholder="Select type"
                        hide-details
                        :items="types"
                        clearable
                      ></v-select>
                      <SelectProxy
                        v-model="tableOptions.proxy"
                        label="Proxy"
                        placeholder="Select proxy"
                        multiple
                        hide-details
                        :is-filter="true"
                      ></SelectProxy>
                    </v-col>
                    <v-col cols="3">
                      <v-select
                        v-model="tableOptions.role"
                        multiple
                        label="Roles"
                        placeholder="Select role"
                        hide-details
                        :items="roles"
                        clearable
                      ></v-select>
                    </v-col>
                    <v-col cols="3">
                      <v-select
                        v-model="tableOptions.project_status"
                        multiple
                        label="Project statuses"
                        placeholder="Select status"
                        hide-details
                        :items="project_statuses"
                        clearable
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-layout>
              </template>
              <!-- SELECT ROW-->
              <template v-slot:header.data-table-select="{ props, on }">
                <CustomSelectRows
                  :value="props"
                  :page="+tableOptions.itemsPerPage"
                  :total="total"
                  :is-all.sync="isAllSelected"
                  v-on="on"
                />
              </template>
              <template v-slot:item.numbering="{ index }">
                {{ getNumbering(index) }}.
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td colspan="2"></td>
                <td
                  class="pa-4"
                  :colspan="headers.length-2"
                >
                  <div class="d-flex align-center">
                    <div class="d-flex flex-column align-start pa-2">
                      <v-chip
                        v-if="!item.proxy"
                        label
                        small
                        text-color="white"
                        color="error"
                        class="mb-2"
                      >
                        No proxy
                      </v-chip>
                      <v-chip
                        v-if="item.spammer"
                        label
                        small
                        text-color="white"
                        color="warning"
                        class="mb-2"
                      >
                        Spammer
                      </v-chip>
                      <v-chip
                        v-if="item.nitro"
                        label
                        class="mb-2"
                        small
                        text-color="white"
                        color="info"
                      >
                        Nitro
                      </v-chip>
                      <v-chip
                        v-if=" item.limited_access"
                        label
                        small
                        text-color="white"
                        color="teal"
                        class="mb-2"
                      >
                        Limited access
                      </v-chip>
                      <v-tooltip
                        v-if="item.verification"
                        right
                      >
                        <template
                          v-slot:activator="{ on, attrs }"
                        >
                          <v-chip
                            label
                            small
                            text-color="white"
                            color="primary"
                            v-bind="attrs"
                            v-on="on"
                          >
                            Verification:
                            <v-icon
                              small
                              right
                            >
                              {{ verificationIcons[item.verification] }}
                            </v-icon>
                          </v-chip>
                        </template>
                        <span>{{ item.verification }}</span>
                      </v-tooltip>
                    </div>
                  </div>
                  <AccountExpand
                    :account="item"
                  />
                </td>
              </template>
              <template v-slot:item.roles="{ value }">
                <v-chip
                  v-for="role in value"
                  :key="role"
                >
                  {{ checkRole(role) }}
                </v-chip>
              </template>
              <template v-slot:item.status="{ item, value }">
                <div class="d-flex align-center">
                  <v-tooltip
                    right
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-if="value === 'active'"
                        class="mr-4"
                        color="success"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ icons.mdiCheckCircleOutline }}
                      </v-icon>
                      <v-icon
                        v-else-if="value === 'locked'"
                        v-bind="attrs"
                        class="mr-4"
                        color="warning"
                        v-on="on"
                      >
                        {{ icons.mdiLockOutline }}
                      </v-icon>
                      <v-icon
                        v-else-if="value === 'banned'"
                        v-bind="attrs"
                        class="mr-4"
                        color="error"
                        v-on="on"
                      >
                        {{ icons.mdiCancel }}
                      </v-icon>
                    </template>
                    <span>{{ value }}</span>
                  </v-tooltip>
                </div>
              </template>
              <template v-slot:item.username="{ item, value }">
                <span>
                  <span>{{ value }}</span>
                </span>
                <span v-show="item.discriminator">#{{ item.discriminator }}</span>
              </template>
              <template v-slot:item.type="{ value }">
                <v-tooltip
                  v-for="item in value"
                  :key="item"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ typeIcons[item] || item }}
                    </v-icon>
                  </template>
                  <span>{{ item }}</span>
                </v-tooltip>
              </template>
              <template v-slot:item.action="{ item }">
                <div class="d-flex justify-end">
                  <v-tooltip
                    close-delay="1500"
                    top
                    :disabled="isExtEnabled==='true'"
                  >
                    <template
                      v-slot:activator="{ on, attrs }"
                    >
                      <v-btn
                        v-bind="attrs"
                        icon
                        class="mr-4"
                        :class="{'disabled--tooltip':!isExtEnabled||!item.token}"
                        v-on="on"
                        @click="() =>{ openDiscordAccount(item.token)}"
                      >
                        <v-icon>{{ icons.mdiDiscord }}</v-icon>
                      </v-btn>
                    </template>
                    <span>Install <a @click="()=>{$router.push({name:'home'})}">chrome Extension</a> to open this account in browser</span>
                  </v-tooltip>
                  <TableActions
                    :loading="item.loading"
                    delete-msg="Remove from project"
                    @edit="openAccountEditModal(item)"
                    @copy="openCopyAccountModal(item.id)"
                    @delete="tryDeleteAccount(item.id)"
                  />
                </div>
              </template>
              <template v-slot:item.tags="{item, value }">
                <template v-if="value&&value.length > 5">
                  <v-chip
                    v-for="el in value.slice(0, item.tagsCount)"
                    :key="el.id"
                    class="ma-2"
                    role="button"
                  >
                    {{ el }}
                  </v-chip>
                  <v-chip
                    class="ma-2"
                    color="primary"
                    @click="()=>{
                      item.isShowMoreTags=!item.isShowMoreTags
                      item.tagsCount=item.isShowMoreTags?value.length:3
                    }"
                  >
                    {{ item.isShowMoreTags?'Hide':`${value.length-3} more` }}
                  </v-chip>
                </template>
                <template v-else>
                  <v-chip
                    v-for="el in value"
                    :key="el.id"
                    class="ma-1"
                    role="button"
                  >
                    {{ el }}
                  </v-chip>
                </template>
              </template>
              <template v-slot:item.project_status="{value}">
                <v-chip
                  v-if="value"
                  :color="projectAccountStatuses[value]&&projectAccountStatuses[value].color"
                >
                  {{ projectAccountStatuses[value]&&projectAccountStatuses[value].text }}
                </v-chip>
              </template>
              <template v-slot:footer="{ props, on }">
                <TableFooter
                  v-bind="props"
                  v-on="on"
                >
                  <v-btn
                    v-if="selectedAccounts.length"
                    depressed
                    :disabled="isAllSelected"
                    :plain="isAllSelected"
                    class="text-body-2 text-capitalize ml-2"
                    :class="[isAllSelected?'white--text':'primary--text']"
                    @click="()=>{
                      if(!isAllSelected){
                        onActionSelected('selectedAccounts')
                      }
                    }"
                  >
                    <span v-if="isAllSelected">All selected</span>
                    <span v-else>Selected: <span class="text-body-2 ml-1">{{ isAllSelected?total:selectedAccounts.length }}</span></span>
                  </v-btn>
                </TableFooter>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-layout>
    </v-skeleton-loader>

    <AccountEditModal
      ref="editAccountModal"
      hide-project
      :project-id="projectId"
      @update="tryUpdateAccount"
    />

    <LeaveGuild
      ref="leaveGuild"
      :project-id="projectId"
      :selected-options="selectedOptions"
    />

    <JoinServer
      ref="joinServer"
      :project-id="projectId"
      :selected-options="selectedOptions"
    ></JoinServer>

    <ExportAccounts
      ref="exportAccounts"
      :selected-accounts="selectedAccounts"
      :is-all-selected="isAllSelected"
      :filters="$route.query"
      :projects-total="total"
    >
    </ExportAccounts>
    <SelectedAccounts
      ref="selectedAccounts"
      :selected-items="selectedAccounts"
      :roles="roles"
      :is-details="true"
      @unselect="(data)=>{deleteSelectedAccounts(data)}"
    >
    </SelectedAccounts>
    <VerifyAccount
      ref="verifyAccount"
      :project-id="projectId"
      :selected-options="selectedOptions"
    ></VerifyAccount>

    <CheckVerification
      ref="checkVerification"
      :project-id="projectId"
      :roles="roles"
      :channel="details.verified_channel_id"
      :selected-options="selectedOptions"
    />

    <ClickButton
      ref="clickButton"
      :project-id="projectId"
      :selected-options="selectedOptions"
    />

    <EmojiPut
      ref="emojiPut"
      :project-id="projectId"
      :selected-options="selectedOptions"
    />
    <EmojiRemove
      ref="emojiRemove"
      :project-id="projectId"
      :selected-options="selectedOptions"
    />

    <CopyAccount
      ref="copyAccountModal"
    />

    <UpdateInfo
      ref="updateInfo"
      :selected-options="selectedOptions"
    />
    <ChangeAvatar
      ref="changeAvatar"
      :selected-options="selectedOptions"
    />
    <ChangeBanner
      ref="changeBanner"
      :selected-options="selectedOptions"
    />
    <ChangeBio
      ref="changeBio"
      :selected-options="selectedOptions"
    />
    <ChangeDiscriminator
      ref="changeDiscriminator"
      :selected-options="selectedOptions"
    />
    <ChangeNickname
      ref="changeNickname"
      :selected-options="selectedOptions"
    />
    <ChangePassword
      ref="changePassword"
      :selected-options="selectedOptions"
    />
    <ChangeProfileColor
      ref="changeProfileColor"
      :selected-options="selectedOptions"
    />
    <ChangeTag
      ref="changeTag"
      :selected-options="selectedOptions"
      :load-accounts="fetchProjectAccounts"
    />
    <ChangeProxy
      ref="changeProxy"
      :selected-options="selectedOptions"
      :load-accounts="fetchProjectAccounts"
    />
    <ChangeType
      ref="changeType"
      :selected-options="selectedOptions"
      :load-accounts="fetchProjectAccounts"
    />
    <ParseAccounts
      ref="parseAccounts"
      :selected-account="selectedAccounts"
      :channel-id="details.giveaway_channel_id"
      :project-id="projectId"
    />

    <ConfirmDialog
      ref="deleteAccountConfirm"
      max-width="400"
    >
      <template v-slot="{agree, cancel}">
        <v-card>
          <v-card-title>Delete account</v-card-title>
          <v-card-text>
            Do you really want to delete account
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="error"
              @click="agree"
            >
              <v-icon>{{ icons.mdiDeleteForever }}</v-icon>
              Delete
            </v-btn>
            <v-btn
              @click="cancel"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </ConfirmDialog>

    <ConfirmDialog
      ref="removeAccountFromProjectConfirm"
      max-width="400"
    >
      <template v-slot="{agree, cancel}">
        <v-card>
          <v-card-title>Remove account</v-card-title>
          <v-card-text>
            Do you really want to remove account(s)
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="error"
              @click="agree"
            >
              <v-icon>{{ icons.mdiDeleteForever }}</v-icon>
              Delete
            </v-btn>
            <v-btn
              @click="cancel"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </ConfirmDialog>

    <!-- <v-overlay :value="initialLoading">
      <v-progress-circular
        indeterminate
        size="125"
      ></v-progress-circular>
    </v-overlay> -->
  </div>
</template>

<script>
import {
  mdiChevronLeft,
  mdiCheckCircleOutline,
  mdiLockOutline,
  mdiDotsVertical,
  mdiMagnify,
  mdiPencil,
  mdiCancel,
  mdiDeleteForever,
  mdiCheck,
  mdiDotsHorizontal,
  mdiFilter,
  mdiShieldOffOutline,
  mdiCellphoneKey,
  mdiEmailCheckOutline,
  mdiCloseCircleOutline,
  mdiCloseOctagonOutline,
  mdiClipboardTextOutline,
  mdiLinkVariantPlus,
  mdiAccountCheck,
  mdiGiftOpenOutline,
  mdiRefresh,
  mdiFormatListBulletedSquare,
  mdiMarkerCheck,
  mdiServerPlus,
  mdiDownload,
  mdiDiscord,
  mdiUpdate,
  mdiExport,
  mdiSync,
} from '@mdi/js'

import DiscordApi from '@core/api/discord'
import { mapActions, mapMutations } from 'vuex'
import pick from 'lodash/pick'
import {
  projectAccountStatuses, types, statuses, verifications,
} from '@core/constants'
import TrueFalse from '@/components/TrueFalse.vue'
import ProjectEditModal from '@/components/discord/project/EditModal.vue'
import AccountEditModal from '@/components/discord/account/EditModal.vue'
import CopyAccount from '@/components/discord/account/modals/CopyAccount.vue'
import TableActions from '@/components/discord/account/TableActions.vue'
import discordStoreModule, { DISCORD_MODULE_NAME } from '@/store/modules/discordStoreModule'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import store from '@/store'
import MultiActions from '../../components/discord/project/MultiActions.vue'
import SelectProxy from '@/components/proxy/SelectProxy.vue'
import LeaveGuild from '@/components/discord/project/modals/LeaveGuild.vue'
import ClickButton from '@/components/discord/project/modals/ClickButton.vue'
import SelectTag from '@/components/discord/account/SelectTag.vue'
import EmojiPut from '@/components/discord/project/modals/EmojiPut.vue'
import EmojiRemove from '@/components/discord/project/modals/EmojiRemove.vue'
import SelectedAccounts from '@/components/discord/account/SelectedAccounts.vue'
import TableFooter from '@/components/TableFooter.vue'
import JoinServer from '@/components/discord/project/modals/JoinServer.vue'
import CheckVerification from '@/components/discord/project/modals/CheckVerification.vue'
import CustomSelectRows from '@/components/CustomSelectRows.vue'
import VerifyAccount from '@/components/discord/project/modals/VerifyAccount.vue'
import UpdateInfo from '@/components/discord/account/modals/UpdateInfo.vue'
import ChangeAvatar from '@/components/discord/account/modals/ChangeAvatar.vue'
import ChangeBanner from '@/components/discord/account/modals/ChangeBanner.vue'
import ChangeBio from '@/components/discord/account/modals/ChangeBio.vue'
import ChangeDiscriminator from '@/components/discord/account/modals/ChangeDiscriminator.vue'
import ChangeNickname from '@/components/discord/account/modals/ChangeNickname.vue'
import ChangePassword from '@/components/discord/account/modals/ChangePassword.vue'
import ChangeProfileColor from '@/components/discord/account/modals/ChangeProfileColor.vue'
import ChangeProxy from '@/components/discord/account/modals/ChangeProxy.vue'
import ChangeType from '@/components/discord/account/modals/ChangeType.vue'
import ExportAccounts from '@/components/ExportAccounts.vue'
import AddNewAccount from '@/components/discord/account/AddNewProjDetails.vue'
import ChangeTag from '@/components/discord/account/modals/ChangeTag.vue'
import TableSearch from '@/components/TableSearch.vue'
import AccountExpand from '@/components/discord/account/AccountExpand.vue'
import ParseAccounts from '@/components/discord/account/modals/ParseAccounts.vue'

export default {
  components: {
    TrueFalse,
    ProjectEditModal,
    AccountEditModal,
    CopyAccount,
    TableActions,
    ConfirmDialog,
    MultiActions,
    SelectProxy,
    LeaveGuild,
    ClickButton,
    SelectTag,
    EmojiPut,
    EmojiRemove,
    TableFooter,
    JoinServer,
    CheckVerification,
    CustomSelectRows,
    VerifyAccount,
    UpdateInfo,
    ChangeAvatar,
    ChangeBanner,
    ChangeBio,
    ChangeDiscriminator,
    ChangeNickname,
    ChangePassword,
    ChangeProfileColor,
    ChangeProxy,
    ChangeType,
    ExportAccounts,
    AddNewAccount,
    SelectedAccounts,
    ChangeTag,
    TableSearch,
    AccountExpand,
    ParseAccounts,
  },
  filters: {
    placeholder(value) {
      return !!value === false ? 'Value is not defined' : value
    },
  },
  data() {
    return {
      types,
      statuses,
      verifications,
      isExtEnabled: sessionStorage.getItem('discordExt'),
      total: 50,
      isAllSelected: false,
      showFilters: false,
      icons: {
        mdiPencil,
        mdiDotsVertical,
        mdiChevronLeft,
        mdiCheckCircleOutline,
        mdiLockOutline,
        mdiMagnify,
        mdiCancel,
        mdiDeleteForever,
        mdiCheck,
        mdiDotsHorizontal,
        mdiFilter,
        mdiShieldOffOutline,
        mdiCellphoneKey,
        mdiEmailCheckOutline,
        mdiCloseCircleOutline,
        mdiCloseOctagonOutline,
        mdiClipboardTextOutline,
        mdiLinkVariantPlus,
        mdiGiftOpenOutline,
        mdiFormatListBulletedSquare,
        mdiRefresh,
        mdiAccountCheck,
        mdiMarkerCheck,
        mdiServerPlus,
        mdiDownload,
        mdiDiscord,
        mdiUpdate,
        mdiExport,
        mdiSync,
      },
      projectAccountStatuses,
      pages: [50, 100, 200],
      verificationLevelMap: {
        0: 'Unrestricted',
        1: 'Email verified',
        2: 'Email verified',
        3: 'Email verified',
        4: 'Phone verified',
      },
      search: '',
      initialLoading: true,
      accountsLoading: true,
      rolesRefreshingLoading: false,
      accounts: [],
      loading: false,
      giveawayLoading: false,
      details: {},
      selectedAccounts: [],
      verificationIcons: {
        'phone verified': mdiCellphoneKey,
        'mail verified': mdiEmailCheckOutline,
        'non verified': mdiShieldOffOutline,
        null: mdiCloseOctagonOutline,
      },
      typeIcons: {
        referral: mdiLinkVariantPlus,
        giveaway: mdiGiftOpenOutline,
        'white list': mdiFormatListBulletedSquare,
      },
      tableOptions: {
        type: this.$route.query.type,
        proxy: +this.$route.query.proxy || null,
        status: this.$route.query.status,
        role: this.$route.query.role,
        project_status: this.$route.query.project_status,
        search: this.$route.query.search || '',
        sortBy: this.$route.query.sortBy ? [this.$route.query.sortBy] : [],
        sortDesc: +this.$route.query.sortDesc ? [Boolean(+this.$route.query.sortDesc)] : [],
        page: +this.$route.query.page ? +this.$route.query.page : 1,
        itemsPerPage: +this.$route.query.limit ? +this.$route.query.limit : localStorage.getItem('itemsPerPage') || 50,
      },
      accountHeaders: [
        {
          cellClass: 'pa-0 text-center',
          value: 'numbering',
          width: '50px',
          align: 'center',
          sortable: false,
        },
        {
          text: '',
          class: 'pa-0 pr-4',
          cellClass: 'pa-0 pr-4',
          value: 'data-table-expand',
        },
        {
          text: '',
          class: 'pa-0',
          cellClass: 'pa-0',
          value: 'data-table-select',
        },
        {
          text: 'Status',
          value: 'status',
          width: '90px',
          sortable: true,
          filterable: true,
        },
        {
          text: 'Username',
          value: 'username',
          sortable: true,
          filterable: true,
        },
        {
          text: 'Type',
          value: 'type',
          sortable: true,
          filterable: true,
          width: '104px',
        },
        {
          text: 'Tags',
          value: 'tags',
          sortable: true,
          filterable: true,
          width: '100px',
        },
        {
          value: 'roles',
          text: 'Roles',
          width: '100px',
        },
        {
          value: 'project_status',
          text: 'Project Status',
          width: '135px',
        },
        {
          align: 'end',
          value: 'action',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    isRefreshRolesRequired() {
      return this.accounts?.length && this.accounts
        ?.some(({ roles }) => roles.length > this.roles.length || roles?.some(roleId => this.roles?.find(({ id }) => id === roleId)))
    },
    roles() {
      return this.details?.roles?.map(({ name, id }) => ({ text: name, value: id })) || []
    },
    project_statuses() {
      return Object.entries(projectAccountStatuses).map(([key, value]) => ({ text: value.text, value: key }))
    },
    projectId() {
      return +this.$route.params.id
    },
    selectedOptions() {
      return this.isAllSelected
        ? {
          useFilter: true,
          filter: {
            ...this.$route.query,
            for_project: true,
            project: [this.$route.params.id],
          },
        }
        : {
          useFilter: false,
          accounts: this.selectedAccounts.map(el => el.id),
        }
    },
    messageLink() {
      return `https://discord.com/channels/${this.details.guild_id}/${this.details.giveaway_channel_id}/${this.details.giveaway_last_message}`
    },
    isMessageLinkAvailable() {
      return this.details.guild_id && this.details.giveaway_channel_id && this.details.giveaway_last_message
    },
  },
  watch: {
    total() {
      this.tableOptions.page = 1
    },
    tableOptions: {
      handler(value) {
        const query = pick(value, ['sortBy', 'page', 'search', 'role', 'status', 'proxy', 'type', 'tags', 'project_status'])

        query.limit = value.itemsPerPage

        if (value.sortBy?.length) {
          [query.sortBy] = value.sortBy
        }

        if (value.sortDesc?.length) {
          [query.sortDesc] = value.sortDesc
        }

        Object.keys(query).forEach(key => {
          if (!query[key] && key !== 'sortDesc') {
            delete query[key]
          }
        })

        this.fetchProjectAccounts(query)
        this.$router.replace({ query }).catch(() => {})
      },
      deep: true,
    },
  },
  mounted() {
    if (!store.hasModule(DISCORD_MODULE_NAME)) {
      store.registerModule(DISCORD_MODULE_NAME, discordStoreModule)
    }
    Promise.all([
      this.fetchProjectDetails(),
      this.fetchProjectAccounts(),
    ])
  },
  methods: {
    ...mapActions({
      updateAccount(dispatch, payload) {
        return dispatch(`${DISCORD_MODULE_NAME}/updateAccount`, payload)
      },
      removeAccount(dispatch, payload) {
        return dispatch(`${DISCORD_MODULE_NAME}/removeAccount`, payload)
      },
    }),

    ...mapMutations({
      commitDeleteAccount(commit, payload) {
        return commit(`${DISCORD_MODULE_NAME}/REMOVE_ACCOUNT`, payload)
      },
    }),

    openDiscordAccount(token) {
      if (this.isExtEnabled === 'true') {
        const event = new CustomEvent('discord-token', { detail: token })

        document.dispatchEvent(event)
      }
    },

    async fetchProjectDetails() {
      const { id } = this.$route.params

      try {
        this.initialLoading = true
        const { data } = await DiscordApi.Project.fetchProject(id)
        // eslint-disable-next-line
        this.details = data;
        this.$route.meta.title = this.details.name
      } catch (err) {
        this.$message.error(err)
      }
      this.initialLoading = false
    },
    async fetchProjectAccounts(params = this.$route.query) {
      const { id } = this.$route.params
      try {
        this.accountsLoading = true
        const { data } = await DiscordApi.Project.fetchProjectDiscordAccount(id, params)
        this.total = data.total
        this.accounts = data.items
        this.accounts = this.accounts.map(account => ({
          ...account,
          isShowMoreTags: false,
          tagsCount: 3,
        }))
      } catch (err) {
        this.$message.error(err)
      }
      this.accountsLoading = false
    },
    onActionSelected(action) {
      if (action === 'delete') {
        this.$refs.removeAccountFromProjectConfirm.open().then(async () => {
          const { id } = this.$route.params
          try {
            await DiscordApi.Project.removeAccount(id, this.selectedOptions)
            this.selectedAccounts = this.selectedAccounts.filter(el => !this.selectedOptions.accounts.includes(el.id))
            await this.fetchProjectAccounts()
          } catch (err) {
            this.$message.error(err)
          }
        })
      } else {
        this.$refs[action]?.open(this.selectedAccounts.map(el => el.id))
      }
    },

    async tryRefreshRoles() {
      try {
        this.rolesRefreshingLoading = true
        await DiscordApi.Project.refreshRoles(this.details.id)
        Promise.all([
          this.fetchProjectDetails(),
          this.fetchProjectAccounts(),
        ])
      } catch (error) {
        this.$message.error(error)
      } finally {
        this.rolesRefreshingLoading = false
      }
    },
    async tryUpdateProject(project) {
      try {
        this.loading = true
        await DiscordApi.Project.updateProject({ project })
        this.details = project
      } catch (error) {
        this.$message.error(error)
      } finally {
        this.loading = false
      }
    },

    async tryDeleteAccount(id) {
      this.$refs.deleteAccountConfirm.open().then(() => {
        const acc = this.accounts.find(el => id === el.id)
        acc.loading = true
        this.removeAccount({ id }).then(() => {
          this.accounts.splice(el => el.id === id, 1)
          this.selectedAccounts = this.selectedAccounts.filter(el => el.id !== id)
        })
      })
    },

    async tryUpdateAccount(account) {
      const acc = this.accounts.find(el => account.id === el.id)
      acc.loading = true
      const { data } = await this.updateAccount({ account })
      this.accounts.splice(el => el.id === account.id, 1, data)
      this.fetchProjectAccounts()
    },

    openAccountEditModal(account) {
      this.$refs.editAccountModal.open(account)
    },

    openCopyAccountModal(account) {
      this.$refs.copyAccountModal.open(account)
    },
    deleteSelectedAccounts(data) {
      this.selectedAccounts = this.selectedAccounts.filter(el => el.id !== data)
    },
    checkParseAccount() {
      if (this.selectedAccounts.length > 0 && this.selectedAccounts.length < 2) {
        this.onActionSelected('parseAccounts')
      } else if (this.selectedAccounts.length > 1) {
        this.$message.info('Only one account can be parsed')
      } else {
        this.$message.info('No accounts selected')
      }
    },
    getNumbering(index) {
      return (this.tableOptions.page - 1) * this.tableOptions.itemsPerPage + (index + 1)
    },
    checkRole(role) {
      const foundRole = this.roles.find(r => r.value === role)

      return foundRole?.text || 'Unknown'
    },
    async checkGiveaway() {
      this.giveawayLoading = true
      const { data } = await DiscordApi.Project.checkGiveaway(this.projectId)
      this.details = data
      this.giveawayLoading = false
    },
  },

}
</script>

<style lang="scss" scoped>
::v-deep .v-expansion-panel {
  &-header {
    padding: 0;
    padding-right: 16px;
  }
  &-content__wrap {
    padding: 0;
  }
}

::v-deep .v-skeleton-loader {
  &__heading {
    margin: 1rem 0;
  }

  &__card {
    margin: 1rem 0;
  }

  &__paragraph {
    margin: 1rem 0;
  }
}
.v-tooltip__content {
  pointer-events: initial;
}
.disabled--tooltip{
  color: rgba(231, 227, 252, 0.3) !important;
}
</style>

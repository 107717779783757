<template>
  <div class="text-end">
    <v-bottom-sheet
      ref="bottom-class"
      v-model="sheet"
      scrollable
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          :attrs="attrs"
          :elevation="12"
          fab
          v-on="on"
        >
          <v-icon>{{ mdiPlus }}</v-icon>
        </v-btn>
      </template>
      <v-tabs v-model="formMode">
        <v-tab>Add new</v-tab>
        <v-tab>Add from existing</v-tab>
        <v-tabs-items v-model="formMode">
          <v-tab-item>
            <AddAccountForm
              v-if="sheet"
              :loading="loading"
              :is-project-details="isProjectDetails"
              :project-id="projectId"
              @submit="submit"
            />
          </v-tab-item>
          <v-tab-item>
            <v-card>
              <v-card-title class="d-flex flex-column align-start">
                Add an account from existing
                <TableSearch
                  v-model="tableOptions.search"

                  :show-filters.sync="showFilters"

                  :check-filter-toggle="checkFilterToggle"
                  class="mt-5"
                />
              </v-card-title>
              <v-card-text class="scroll-add pb-0">
                <v-data-table
                  v-model="selectedAccounts"
                  hide-default-footer
                  :mobile-breakpoint="0"
                  :server-items-length="total"
                  item-key="id"
                  loading-text="Loading accounts ..."
                  show-select
                  show-expand
                  :loading="loading"
                  :items="accounts"
                  :headers="accountHeaders"
                  :options.sync="tableOptions"
                  :footer-props="{
                    'items-per-page-options': pages,
                    'show-first-last-page': true,
                    'show-current-page': true,
                  }"
                >
                  <template
                    v-slot:top
                  >
                    <v-layout
                      v-show="showFilters"
                      class="pa-4 pt-0 mt-4"
                    >
                      <v-row>
                        <v-col cols="3">
                          <v-range-slider
                            v-model="projectRange"
                            thumb-label
                            :height="42"
                            class="mt-4"
                            :step="1"
                            :min="0"
                            :max="totalProjects"
                            dense
                            hide-details
                            label="Project Count"
                          ></v-range-slider>
                          <SelectProject
                            v-model="tableOptions.project"
                            label="Projects"
                            placeholder="Select projects(s)"
                            multiple
                            hide-details
                            :is-filter="true"
                          ></SelectProject>
                          <SelectTag
                            v-model="tableOptions.tags"
                            multiple
                          />
                        </v-col>
                        <v-col cols="3">
                          <v-select
                            v-model="tableOptions.status"
                            label="Status"
                            placeholder="Select status"
                            hide-details
                            multiple
                            chips
                            :items="statuses"
                            clearable
                          >
                          </v-select>
                          <v-select
                            v-model="tableOptions.verification"
                            label="Verification"
                            placeholder="Select verification"
                            multiple
                            chips
                            hide-details
                            :items="verifications"
                            clearable
                          ></v-select>
                        </v-col>
                        <v-col cols="3">
                          <v-select
                            v-model="tableOptions.type"
                            label="Types"
                            placeholder="Select type(s)"
                            multiple
                            chips
                            hide-details
                            :ripple="false"
                            :items="types"
                            clearable
                          >
                            <template v-slot:item="data">
                              <v-simple-checkbox
                                :value="data.attrs['aria-selected'] === 'true'"
                              />
                              <v-list-item-icon class="mr-2">
                                <v-icon v-text="typeIcons[data.item.value]"></v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>{{ data.item.text }}</v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </v-select>
                          <SelectProxy
                            v-model="tableOptions.proxy"
                            label="Proxy"
                            placeholder="Select proxy"
                            hide-details
                            multiple
                            :is-filter="true"
                          ></SelectProxy>
                        </v-col>
                        <v-col cols="3">
                          <v-checkbox
                            v-model="tableOptions.nitro"
                            hide-details
                            label="Nitro"
                          />
                          <v-checkbox
                            v-model="tableOptions.spam"
                            hide-details
                            label="Spam"
                          />
                        </v-col>
                      </v-row>
                    </v-layout>
                  </template>
                  <template v-slot:item.numbering="{ index }">
                    {{ getNumbering(index) }}.
                  </template>
                  <template v-slot:expanded-item="{ headers, item }">
                    <td colspan="2"></td>
                    <td
                      class="pa-4"
                      :colspan="headers.length-2"
                    >
                      <AccountExpand
                        :account="item"
                      />
                    </td>
                  </template>
                  <template v-slot:item.status="{ item, value }">
                    <div class="d-flex align-center">
                      <v-tooltip
                        right
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-if="value === 'active'"
                            class="mr-4"
                            color="success"
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{ icons.mdiCheckCircleOutline }}
                          </v-icon>
                          <v-icon
                            v-else-if="value === 'locked'"
                            v-bind="attrs"
                            class="mr-4"
                            color="warning"
                            v-on="on"
                          >
                            {{ icons.mdiLockOutline }}
                          </v-icon>
                          <v-icon
                            v-else-if="value === 'banned'"
                            v-bind="attrs"
                            class="mr-4"
                            color="error"
                            v-on="on"
                          >
                            {{ icons.mdiCancel }}
                          </v-icon>
                        </template>
                        <span>{{ value }}</span>
                      </v-tooltip>
                    </div>
                  </template>
                  <template v-slot:item.username="{ item, value }">
                    <span dir="ltr">{{ value }}</span>
                    <span v-show="item.discriminator">#{{ item.discriminator }}</span>
                  </template>
                  <template v-slot:item.verified="{ item }">
                    <div class="d-flex align-center">
                      <div class="d-flex flex-column align-start pa-2">
                        <v-chip
                          v-if="item.spammer"
                          label
                          small
                          text-color="white"
                          color="warning"
                          class="mb-2"
                        >
                          Spammer
                        </v-chip>
                        <v-chip
                          v-if="item.nitro"
                          label
                          class="mb-2"
                          small
                          text-color="white"
                          color="info"
                        >
                          Nitro
                        </v-chip>
                        <v-chip
                          v-if="!item.proxy"
                          label
                          small
                          text-color="white"
                          color="error"
                          class="mb-2"
                        >
                          No proxy
                        </v-chip>
                        <v-chip
                          v-if=" item.limited_access"
                          label
                          small
                          text-color="white"
                          color="teal"
                          class="mb-2"
                        >
                          Limited access
                        </v-chip>
                        <v-tooltip
                          v-if="item.verification"
                          right
                        >
                          <template
                            v-slot:activator="{ on, attrs }"
                          >
                            <v-chip
                              label
                              small
                              text-color="white"
                              color="primary"
                              v-bind="attrs"
                              v-on="on"
                            >
                              Verification:
                              <v-icon
                                small
                                right
                              >
                                {{ verificationIcons[item.verification] }}
                              </v-icon>
                            </v-chip>
                          </template>
                          <span>{{ item.verification }}</span>
                        </v-tooltip>
                      </div>
                    </div>
                  </template>
                  <template v-slot:item.type="{ value }">
                    <v-tooltip
                      v-for="item in value"
                      :key="item"
                      bottom
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ typeIcons[item] || item }}
                        </v-icon>
                      </template>
                      <span>{{ item }}</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.projects="{item, value }">
                    <template v-if="value&&value.length > 5">
                      <v-chip
                        v-for="el in value.slice(0,item.projectsCount)"
                        :key="el.id"
                        class="ma-2"
                        role="button"
                        :to="{name: 'discordProjectDetails', params: {id: el.id}}"
                      >
                        {{ el.name }}
                        <v-icon
                          small
                          class="ml-1"
                          color="primary"
                        >
                          {{ icons.mdiOpenInNew }}
                        </v-icon>
                      </v-chip>
                      <v-chip
                        color="primary"
                        class="ma-2"
                        @click="()=>{
                          item.isShowMoreProjects=!item.isShowMoreProjects
                          item.projectsCount=item.isShowMoreProjects?value.length:3
                        }"
                      >
                        {{ item.isShowMoreProjects?'Hide':`${value.length-3} more` }}
                      </v-chip>
                    </template>
                    <template v-else>
                      <v-chip
                        v-for="el in value"
                        :key="el.id"
                        class="ma-1"
                        role="button"
                        :to="{name: 'discordProjectDetails', params: {id: el.id}}"
                      >
                        {{ el.name }}
                        <v-icon
                          class="ml-1"
                          small
                          color="primary"
                        >
                          {{ icons.mdiOpenInNew }}
                        </v-icon>
                      </v-chip>
                    </template>
                  </template>
                  <template v-slot:item.tags="{item, value }">
                    <template v-if="value&&value.length > 5">
                      <v-chip
                        v-for="el in value.slice(0, item.tagsCount)"
                        :key="el.id"
                        class="ma-2"
                        role="button"
                      >
                        {{ el }}
                      </v-chip>
                      <v-chip
                        class="ma-2"
                        color="primary"
                        @click="()=>{
                          item.isShowMoreTags=!item.isShowMoreTags
                          item.tagsCount=item.isShowMoreTags?value.length:3
                        }"
                      >
                        {{ item.isShowMoreTags?'Hide':`${value.length-3} more` }}
                      </v-chip>
                    </template>
                    <template v-else>
                      <v-chip
                        v-for="el in value"
                        :key="el.id"
                        class="ma-2"
                        role="button"
                      >
                        {{ el }}
                      </v-chip>
                    </template>
                  </template>
                  <template v-slot:footer="{ props, on }">
                    <TableFooter
                      v-bind="props"
                      v-on="on"
                    >
                      <v-btn
                        v-if="selectedAccounts.length"
                        depressed
                        class="text-body-2 text-capitalize ml-2 primary--text"
                        @click="()=>{
                          openSelectedAccountsModal()
                        }"
                      >
                        Selected: <span class="text-body-2 ml-1">{{ selectedAccounts.length }}</span>
                      </v-btn>
                    </TableFooter>
                  </template>
                </v-data-table>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-row>
                  <v-col
                    cols="12"
                    lg="12"
                  >
                    <v-btn
                      color="primary"
                      :loading="loading"
                      @click="addAccount"
                    >
                      Add
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>

      <SelectedAccounts
        ref="selectedAccounts"
        :selected-items="selectedAccounts"
        :item-headers="computedHeaders"
        @unselect="(data)=>{deleteSelectedAccounts(data)}"
      >
      </SelectedAccounts>
    </v-bottom-sheet>
  </div>
</template>

<script>
import {
  mdiDeleteForever,
  mdiCheck,
  mdiCancel,
  mdiCheckCircleOutline,
  mdiLockOutline,
  mdiDotsVertical,
  mdiDotsHorizontal,
  mdiFilter,
  mdiPencil,
  mdiMagnify,
  mdiShieldOffOutline,
  mdiCellphoneKey,
  mdiEmailCheckOutline,
  mdiCloseCircleOutline,
  mdiCloseOctagonOutline,
  mdiClipboardTextOutline,
  mdiLinkVariantPlus,
  mdiGiftOpenOutline,
  mdiFormatListBulletedSquare,
  mdiDownload,
  mdiOpenInNew,
  mdiPlus,
} from '@mdi/js'
import {
  projectAccountStatuses, statuses, verifications, types,
} from '@core/constants'
import DiscordApi from '@core/api/discord'
import _cloneDeep from 'lodash/cloneDeep'
import pick from 'lodash/pick'
import store from '@/store'
import TableFooter from '@/components/TableFooter.vue'
import AddAccountForm from './AddForm.vue'
import SelectProject from '@/components/discord/project/SelectProject.vue'
import SelectTag from '@/components/discord/account/SelectTag.vue'
import SelectProxy from '@/components/proxy/SelectProxy.vue'
import { DISCORD_MODULE_NAME } from '@/store/modules/discordStoreModule'
import TableSearch from '@/components/TableSearch.vue'
import { castToArray } from '@/@core/utils'
import SelectedAccounts from '@/components/discord/account/SelectedAccounts.vue'
import AccountExpand from '@/components/discord/account/AccountExpand.vue'

export default {
  components: {
    AddAccountForm, TableFooter, SelectProject, SelectTag, SelectProxy, TableSearch, SelectedAccounts, AccountExpand,
  },
  props: {
    isProjectDetails: {
      type: Boolean,
      default: false,
    },
    projectId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      mdiPlus,
      sheet: false,
      loading: false,
      formMode: 0,
      accounts: [],
      selectedAccounts: [],
      total: 1,
      pages: [50, 100, 200],
      totalProjects: 0,
      defaultOptions: {},
      projectRange: [
        0,
        0,
      ],
      tableOptions: {
        project_count_min: 0,
        project_count_max: 0,
        spam: false,
        nitro: false,
        type: castToArray(null),
        proxy: castToArray(null),
        status: castToArray(null),
        verification: castToArray(null),
        project: castToArray(null),
        tags: castToArray(null),
        search: '',
        sortBy: [],
        sortDesc: [],
        page: 1,
        itemsPerPage: 50,
      },
      showFilters: false,
      statuses,
      verifications,
      types,
      verificationIcons: {
        'phone verified': mdiCellphoneKey,
        'mail verified': mdiEmailCheckOutline,
        'non verified': mdiShieldOffOutline,
        null: mdiCloseOctagonOutline,
      },
      typeIcons: {
        referral: mdiLinkVariantPlus,
        giveaway: mdiGiftOpenOutline,
        'white list': mdiFormatListBulletedSquare,
      },
      icons: {
        mdiDotsVertical,
        mdiDotsHorizontal,
        mdiFilter,
        mdiDeleteForever,
        mdiPencil,
        mdiMagnify,
        mdiLockOutline,
        mdiCheck,
        mdiCloseCircleOutline,
        mdiCheckCircleOutline,
        mdiClipboardTextOutline,
        mdiCancel,
        mdiDownload,
        mdiOpenInNew,
      },
      projectAccountStatuses,
      accountHeaders: [
        {
          cellClass: 'pa-0 text-center',
          value: 'numbering',
          width: '50px',
          align: 'center',
          sortable: false,
        },
        {
          text: '',
          class: 'pa-0 pr-4',
          cellClass: 'pa-0 pr-4',
          value: 'data-table-expand',
        },
        {
          text: '',
          class: 'pa-0',
          cellClass: 'pa-0',
          value: 'data-table-select',
        },
        {
          text: 'Status',
          value: 'status',
          width: '90px',
          sortable: true,
          filterable: true,
        },
        {
          text: 'Username',
          value: 'username',
          sortable: true,
          filterable: true,
        },
        {
          text: 'Type',
          value: 'type',
          sortable: true,
          filterable: true,
          width: '104px',
        },
        {
          text: 'Projects',
          value: 'projects',
          sortable: true,
          filterable: true,
          width: '20%',
        },
        {
          text: 'Tags',
          value: 'tags',
          sortable: true,
          filterable: true,
          width: '20%',
        },
        {
          text: 'Status',
          value: 'verified',
          sortable: true,
          filterable: true,
        },
      ],
    }
  },
  computed: {
    accountsIds() {
      return this.selectedAccounts.map(item => item.id)
    },
    computedHeaders() {
      return [...this.accountHeaders, {
        align: 'end',
        value: 'action',
        sortable: false,
      }]
    },
  },
  watch: {
    projectRange: {
      handler([min, max]) {
        this.tableOptions.project_count_min = min || 0
        this.tableOptions.project_count_max = max || 0
      },
      deep: true,
    },
    tableOptions: {
      handler(value) {
        const query = pick(value, [
          'page', 'search', 'type', 'proxy', 'project',
          'status', 'verification', 'spam', 'nitro',
          'project_count_min', 'project_count_max', 'tags',
        ])

        query.limit = value.itemsPerPage

        if (value.sortBy?.length) {
          [query.sortBy] = value.sortBy
        }

        if (value.sortDesc?.length) {
          [query.sortDesc] = value.sortDesc
        }

        Object.keys(query).forEach(key => {
          if (!query[key] && key !== 'sortDesc') {
            delete query[key]
          }
        })
        this.tryFetchAccounts(query)
      },
      deep: true,
    },
  },
  async mounted() {
    this.getProjectsCount()
  },
  methods: {
    async submit(account) {
      this.loading = true
      try {
        const { data } = await store.dispatch(`${DISCORD_MODULE_NAME}/addAccount`, { account })

        this.sheet = false
        this.loading = false
        this.$emit('submitted')

        this.$message.success(data.message || 'Account added successfully')
      } catch (err) {
        this.sheet = false
        this.loading = false
        this.$message.error(err)
      }
    },
    async addAccount() {
      this.loading = true
      try {
        await DiscordApi.Account.changeProject({ ...{ accounts: this.accountsIds }, ...{ add: [this.projectId] } })
        this.sheet = false
        this.loading = false
        this.$emit('submitted')
        this.$message.success('Accounts successfully added to the project')
      } catch (err) {
        this.sheet = false
        this.loading = false
        this.$message.error(err)
      }
    },
    async tryFetchAccounts(params) {
      this.loading = true
      const data = await DiscordApi.Account.fetchAccounts({ ...params })
      this.accounts = data.data.items
      this.total = data.data.total
      this.loading = false
    },
    checkFilterToggle() {
      const filters = _cloneDeep(this.tableOptions)
      const tableKeys = ['search', 'sortBy', 'sortDesc', 'page', 'itemsPerPage', 'groupBy', 'groupDesc', 'multiSort', 'mustSort']
      let filtersStatus = false
      Object.entries(filters).forEach(([key, value]) => {
        if (tableKeys.includes(key)) {
          delete filters[key]
        } else if ((!!value !== false && !Array.isArray(value)) || (value?.length && value?.length > 0)) {
          filtersStatus = true
        }
      })

      return filtersStatus
    },
    openSelectedAccountsModal() {
      this.$refs.selectedAccounts.open()
    },
    deleteSelectedAccounts(data) {
      this.selectedAccounts = this.selectedAccounts.filter(el => el.id !== data)
    },
    getNumbering(index) {
      return (this.tableOptions.page - 1) * this.tableOptions.itemsPerPage + (index + 1)
    },
    async getProjectsCount() {
      const { data } = await DiscordApi.Project.fetchProjects()
      this.totalProjects = data.total
    },
  },
}
</script>

<style lang='scss'>
.scroll-add{
  overflow-y: auto;
  max-height: 65vh;
  @media only screen and (max-height: 950px) {
  max-height: 60vh;
}
@media only screen and (max-height: 775px) {
  max-height: 55vh;
}
}
</style>

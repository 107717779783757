<template>
  <v-dialog
    v-model="isOpen"
    width="90vw"
    persistent
    @click:outside="tryClose"
  >
    <v-card>
      <v-card-title>
        Parse account
        <v-spacer />
        <v-btn
          icon
          @click="tryClose"
        >
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col>
              <label for="channel_id">
                Channel id
              </label>
              <v-text-field
                id="channel_id"
                v-model="formData.channel_id"
                type="number"
                :rules="[simpleRequired]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <label for="list_name">
                List name
              </label>
              <v-text-field
                id="list_name"
                v-model="formData.list_name"
                :rules="[isNotEmptyString,stringLengthValidation(null,255)]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <label for="items_count">
                Items count
              </label>
              <v-text-field
                id="items_count"
                v-model.number="formData.items_count"
                type="number"
                :rules="[simpleRequired,maximumValue(300)]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <label>
                Targets
              </label>
              <v-checkbox
                v-model="formData.targets"
                value="nickname"
                label="Nickname"
              />
              <v-checkbox
                v-model="formData.targets"
                value="avatar"
                label="Avatar"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider />

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :loading="loading"
          :disabled="formData.targets.length===0"
          @click="submit"
        >
          Parse
        </v-btn>
        <v-btn
          :disabled="loading"
          @click="tryClose"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
    <ConfirmDialog
      ref="updateConfirm"
      :width="400"
      :z-index="99"
    >
      <template v-slot:default="{agree, cancel}">
        <v-card>
          <v-card-title>Confirm update cancel</v-card-title>
          <v-card-text>
            You have unsaved changes, you wanna leave
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              @click="agree"
            >
              Agree
            </v-btn>
            <v-btn
              color="primary"
              @click="cancel"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </ConfirmDialog>
  </v-dialog>
</template>

<script>
import { mdiClose } from '@mdi/js'

import DiscordApi from '@core/api/discord'
import _cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import {
  simpleRequired, isNotEmptyString, stringLengthValidation, maximumValue,
} from '@core/utils/validation'
import ConfirmDialog from '../../../ConfirmDialog.vue'

const DEFAULT_DATA = {
  list_name: '',
  targets: [],
  items_count: null,
}

export default {
  components: { ConfirmDialog },
  props: {
    selectedAccount: {
      type: Array,
      default: () => ({}),
    },
    channelId: {
      type: String,
      default: null,
    },
    projectId: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    icons: {
      mdiClose,
    },
    isOpen: false,
    loading: false,
    initFormData: DEFAULT_DATA,
    formData: DEFAULT_DATA,
  }),
  computed: {
    isChanged() {
      return !isEqual(this.initFormData, this.formData)
    },
  },
  methods: {
    simpleRequired,
    isNotEmptyString,
    stringLengthValidation,
    maximumValue,
    open() {
      this.isOpen = true
      this.initFormData = { ...this.initFormData, channel_id: this.channelId, discord_account_id: this.selectedAccount[0].id }
      this.formData = _cloneDeep(this.initFormData)
    },
    async submit() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true
          await DiscordApi.Project.parseAccount(this.projectId, this.formData)
          this.reset()
          this.$message.success('Account parsed successfully')
        } catch (error) {
          this.$message.error(error)
        } finally {
          this.loading = false
          this.reset()
        }
      }
    },
    reset() {
      this.isOpen = false
    },
    async tryClose() {
      if (this.isChanged && await this.$refs.updateConfirm.open()) {
        this.reset()
      } else this.reset()
    },
  },
}
</script>

<template>
  <v-dialog
    v-model="isOpen"
    width="90vw"
    persistent
    scrollable
    @click:outside="tryClose"
  >
    <v-card>
      <v-card-title>
        Edit project
        <v-spacer />
        <v-btn
          icon
          @click="tryClose"
        >
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="scroll-add">
        <EditProjectForm
          v-if="project"
          ref="form"
          :project="project"
        />
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="submit"
        >
          Update
        </v-btn>
        <v-btn @click="tryClose">
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>

    <!--
      CONFIRM CANCEL UPDATE
    -->
    <ConfirmDialog
      ref="updateConfirm"
      :width="400"
      :z-index="99"
    >
      <template v-slot:default="{agree, cancel}">
        <v-card>
          <v-card-title>Confirm update cancel</v-card-title>
          <v-card-text>
            You have unsaved changes, you wanna leave
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              @click="agree"
            >
              Agree
            </v-btn>
            <v-btn
              color="primary"
              @click="cancel"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </ConfirmDialog>
  </v-dialog>
</template>

<script>
import { mdiClose } from '@mdi/js'

import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'

import ConfirmDialog from '../../ConfirmDialog.vue'
import EditProjectForm from './EditForm.vue'

const DEFAULT_NICKNAME = {
}

export default {
  components: { ConfirmDialog, EditProjectForm },
  data: () => ({
    icons: {
      mdiClose,
    },
    isOpen: false,
    initProject: DEFAULT_NICKNAME,
    project: DEFAULT_NICKNAME,
  }),
  computed: {
    isChanged() {
      return !isEqual(this.initProject, this.project)
    },
  },
  methods: {
    open(project) {
      this.isOpen = true
      this.initProject = cloneDeep(project)
      this.project = cloneDeep(project)
    },
    async submit() {
      if (this.$refs.form.validate()) {
        try {
          const data = await this.$refs.form.submit()
          this.$emit('update', data)
          this.reset()
          this.$message.success('Project updated successfully')
        } catch (err) {
          this.reset()
          this.$message.error(err)
        }
      }
    },
    reset() {
      this.isOpen = false
      this.project = null
    },
    async tryClose() {
      if (this.isChanged && await this.$refs.updateConfirm.open()) {
        this.reset()
      } else this.reset()
    },
  },
}
</script>

<style>

</style>
